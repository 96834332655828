:root {
  --black: #0f0f10;
  --cream: #fcfaf9;
  --white: #f6eee7;
  --main: #2d9cdb;
  --accent: #be4f37;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}
