/* Styles  */

/* Layout */

.container-large {
    max-width: 1400px;
    margin: 0 auto;
}

header {
    width: 100%;
    margin: 2rem 1rem;
}

nav ul {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    list-style: none;
}
nav ul li:last-child {
    margin-left: auto;
}

main {
    text-align: center;
    font-size: 18px;
}

.jumbo {
    margin-top: 28vh;
    font-size: 70px;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.03em;
}

.content-wrapper {
    margin: 1rem auto;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    text-align: center;
}
.content-wrapper span {
    font-weight: 700;
}

.content-wrapper a:link,
a:visited {
    color: var(--black);
    font-weight: 700;
    text-decoration: underline;
}

button {
    width: 100%;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    margin-top: auto;
}

.buttons-wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.cta {
    background: var(--black);
    color: var(--white);
    border: none;
}

.outlined {
    background: var(--white-2);
    color: var(--black);
    border: 1px solid var(--black);
}

@media only screen and (min-width: 768px) {
    .jumbo {
        margin-top: 28;
        font-size: 117px;
    }

    .content-wrapper {
        max-width: 60%;
    }

    .buttons-wrapper {
        flex-direction: row;
    }

    button,
    .cta {
        width: fit-content;
        margin-top: 0;
    }
}
